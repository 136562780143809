var content = require("!!../node_modules/.pnpm/@angular-devkit+build-angular@0.803.29_s3xunxzzkwqsag7kecmgdsz5ea/node_modules/@angular-devkit/build-angular/src/angular-cli-files/plugins/raw-css-loader.js!../node_modules/.pnpm/postcss-loader@3.0.0/node_modules/postcss-loader/src/index.js??embedded!../node_modules/.pnpm/less-loader@5.0.0_less@3.9.0+webpack@4.39.2/node_modules/less-loader/dist/cjs.js??ref--16-3!./styles.less");

if (typeof content === 'string') {
  content = [[module.id, content, '']];
}

var options = {}

options.insert = "head";
options.singleton = false;

var update = require("!../node_modules/.pnpm/style-loader@1.0.0_webpack@4.39.2/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js")(content, options);

if (content.locals) {
  module.exports = content.locals;
}
